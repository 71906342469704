jQuery(document).ready(function() {
	// load slider for Headslider

	
jQuery('.startslider').slick({
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 4,
  arrows: true,
  autoplay: false,
  responsive: [
    {
      breakpoint: 1624,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 1324,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
});

jQuery('.productslider').slick({
  dots: true,
  infinite: true,
  dots: false,
  speed: 300,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1424,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }
  ]
});
  jQuery('.productsslider').slick({
    dots: true,
    infinite: true,
    dots: false,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1824,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
});


jQuery('.karussellslider3').slick({
  centerMode: true,
  centerPadding: '0px',
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 3
      }
    },
    {
      breakpoint: 480,
      settings: {
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 1
      }
    }
  ] 
});


jQuery('.karussellslider4').slick({ 
  dots: false,
  infinite: false,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
});
		
jQuery('.teamslider').slick({
  centerMode: false,
  slidesToShow: 3,
  arrows: true,
  dots: false,
  infinite: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 480,
      settings: {

        slidesToShow: 1
      }
    }
  ] 
});

jQuery('.geschichte').slick({
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  arrows: false,
  autoplay: false,
  autoplaySpeed: 14000,
  asNavFor: '.geschichte-nav' 
});

jQuery('.geschichte-nav').slick({
  slidesToScroll: 1,
  asNavFor: '.geschichte',
  slidesToShow: 12,
  dots: false,
  arrows: false,
  centerMode: true,
  infinite: false,
  focusOnSelect: true
});

		
jQuery('.schaufensterslider').slick({
  centerMode: false,
  slidesToShow: 2,
  arrows: true,
  dots: false,
  infinite: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 480,
      settings: {

        slidesToShow: 1
      }
    }
  ] 
});



  jQuery('.childslider').slick({
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 18,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0px',
    responsive: [
      {
        breakpoint: 2424,
        settings: {
          slidesToShow: 16,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 2224,
        settings: {
          slidesToShow: 14,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 2024,
        settings: {
          slidesToShow: 12,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 1824,
        settings: {
          slidesToShow: 10,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 1614,
        settings: {
          slidesToShow: 8
        }
      },
      {
        breakpoint: 1414,
        settings: {
          slidesToShow: 6
        }
      },
      {
        breakpoint: 1214,
        settings: {
          slidesToShow: 5
        }
      },
      {
        breakpoint: 1014,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 814,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 614,
        settings: {
          slidesToShow: 3
        }
      },
    ]
  });


jQuery('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.6 37.3"><path d="M21.3 2.2l-3.4 3.4L28.7 16.4 4.1 16.3l-0.1 5.3 24.5-0.1L18.1 32.1l3.3 3.2 16.6-16.6L21.3 2.2zM21.3 2.2"/></svg>').appendTo( ".startslider > .slick-next ,.startslider > .slick-prev");
jQuery('<svg xmlns="http://www.w3.org/2000/svg" width="15.691" height="26.554" viewBox="0 0 15.691 26.554"><g id="Backward_arrow" data-name="Backward arrow" transform="translate(0 0)"><path id="Pfad_10" class="arrow" data-name="Pfad 10" d="M2.414,0,0,2.414,10.79,13.277h0L0,24.14l2.414,2.414L15.691,13.277Z" transform="translate(15.691 26.554) rotate(180)"/></g></svg>').appendTo( ".productsslider .slick-next ,.productsslider .slick-prev, .childslider .slick-next ,.childslider .slick-prev");
jQuery('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 407.9 546.5"><polygon points="65.4 81.4 259.1 275 64.9 473.8 107.3 517.2 348.4 275.6 108.8 36 "/></svg>').appendTo( ".schaufensterslider .slick-next , .schaufensterslider .slick-prev, .productslider .slick-next , .productslider .slick-prev, .teamslider .slick-next , .teamslider .slick-prev, .karussellslider4 .slick-next , .karussellslider4 .slick-prev, .karussellslider3 .slick-next , .karussellslider3 .slick-prev");	

});