/*
These functions make sure WordPress
and Foundation play nice together.
*/
jQuery(document).ready(function() {
    // Remove empty P tags created by WP inside of Accordion and Orbit
    jQuery('.accordion p:empty, .orbit p:empty').remove();

	// Adds Flex Video to YouTube and Vimeo Embeds
	jQuery('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function() {
		if ( jQuery(this).innerWidth() / jQuery(this).innerHeight() > 1.5 ) {
		  jQuery(this).wrap("<div class='widescreen responsive-embed'/>");
		} else {
		  jQuery(this).wrap("<div class='responsive-embed'/>");
		}
	});
	
	jQuery(".hamburger").click(function() {
		jQuery('.hamburger').toggleClass("is-active");
	});

	jQuery(".symmen, .symmen-field .close").click(function() {
		jQuery('.symmen-field').toggleClass("is-active");
		jQuery(this).toggleClass("is-active");
	});


	jQuery(".firstsymmen .menuitem").hover(function() {
		jQuery(".firstsymmen .menuitem").removeClass("is-active");
		jQuery(".secondsymmen").removeClass("is-active");
		jQuery(".secondsymmen .menuitem").removeClass("is-active");
		jQuery(".theirdmen").removeClass("is-active");
		var $childmen = '.' + jQuery(this).attr('data-child-men');
		jQuery(this).addClass("is-active");
		jQuery($childmen).addClass("is-active");
	});

	jQuery(".secondsymmen .menuitem.haschild").hover(function() {
		jQuery(".secondsymmen .menuitem").removeClass("is-active");
		jQuery(".theirdmen").removeClass("is-active");
		var $childmen = '.' + jQuery(this).attr('data-child-men');
		jQuery(this).addClass("is-active");
		jQuery($childmen).addClass("is-active");
	});

	jQuery(".js-off-canvas-overlay.is-overlay-fixed").click(function() {
		jQuery('.hamburger').toggleClass("is-active");
	});

	jQuery(".scrolldown").click(function(){
		jQuery('html, body').animate({
			scrollTop: window.pageYOffset + (jQuery(window).height()-45)+'px'
		}, 800);
	});

	// Search field
	jQuery('.searchopen').on( 'click', function() {
		if(jQuery( ".searchinner" ).is(':visible')){
			jQuery( ".searchinner" ).slideUp();
			jQuery('.searchopen').removeClass('active');
		}else{
			jQuery( ".searchinner" ).slideDown();
			jQuery('.searchopen').addClass('active');
			jQuery("input.search-field").focus();
		}
	});	
	  
	jQuery('.galleryi').featherlightGallery({
					gallery: {
						fadeIn: 300,
						fadeOut: 300, 
					},		
					openSpeed:    300,
					closeSpeed:   300
	});	  
	
	//Sortierung Größen in Shop-Einzel
	var values = {};
	var n = 0;
	jQuery('.value div').each(function() {
  		var istr = jQuery(this).find('label').text();
  		if(istr.includes('½')){
  			istr = parseInt(istr);
  			istr+=0.5;
  			istr=istr*10;
  		}else{
  			istr = parseInt(istr*10);
  		}
		values[istr]= jQuery(this).html();
 	});
 	
 	jQuery('.value div').remove();  	

	Object.keys(values).forEach(function(key) {
	  jQuery('.value').append("<div>"+ values[key] + "</div>");
	});



	
	//Ausgewählte Größe anzeigen
	jQuery('.value div input').click(function(){
		jQuery('label.checkedClass').removeClass('checkedClass');
	    jQuery(this).next().toggleClass('checkedClass'); // checkedClass is defined in your CSS
	});	

	// Größen reseten
	jQuery('.reset_variations').click(function(){
		jQuery('label.checkedClass').removeClass('checkedClass');
	});

	window.setTimeout( jQuery('#preloader').fadeOut(), 2000 ); // 2 seconds

});


jQuery(window).scroll(function(event) {	  
	  
});

jQuery(window).on('pageshow',function(){

}); //code here });

window.addEventListener("touchend",function(event){
	var offset = 100;//at least 100px are a swipe
	if(start){
		//the only finger that hit the screen left it
		var end = event.changedTouches.item(0).clientX;

		if(end > start + offset){
			jQuery(".nextpro a").click();
		}
		if(end < start - offset ){
			jQuery(".nextpro a").click();
		}
	}
});






